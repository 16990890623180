/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlatformSettingsResponseDTO } from '../models/PlatformSettingsResponseDTO';
import type { UpdatePlatformSettingsDTO } from '../models/UpdatePlatformSettingsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PlatformSettingsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Update Platform Settings
     * @returns void 
     * @throws ApiError
     */
    public updatePlatformSettings({
formData,
}: {
formData: UpdatePlatformSettingsDTO,
}): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/platform-settings',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
            },
        });
    }

    /**
     * Get Platform Settings
     * @returns PlatformSettingsResponseDTO Platform Settings
     * @throws ApiError
     */
    public getPlatformSettings(): CancelablePromise<PlatformSettingsResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform-settings',
        });
    }

}
