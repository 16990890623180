/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateProductDTO = {
    name?: string;
    description?: string;
    price?: number;
    value?: number;
    type?: UpdateProductDTO.type;
    interval?: UpdateProductDTO.interval;
    userRole?: UpdateProductDTO.userRole;
    status?: UpdateProductDTO.status;
    /**
     * Users who will be able to see this entry. Send the _id
     */
    onlyUsers?: Array<string>;
};

export namespace UpdateProductDTO {

    export enum type {
        INVITE = 'invite',
        BOOST_FEED = 'boostFeed',
    }

    export enum interval {
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
        YEAR = 'year',
    }

    export enum userRole {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}
