/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';
import type { PayoutDetailsPaypalResponseDTO } from './PayoutDetailsPaypalResponseDTO';
import type { PayoutDetailsStripeBankResponseDTO } from './PayoutDetailsStripeBankResponseDTO';
import type { PayoutDetailsStripeCardResponseDTO } from './PayoutDetailsStripeCardResponseDTO';
import type { TransactionBookingResponseDTO } from './TransactionBookingResponseDTO';
import type { TransactionProductResponseDTO } from './TransactionProductResponseDTO';
import type { UserListResponseDTO } from './UserListResponseDTO';

export type TransactionResponseDTO = {
    _id: string;
    /**
     * Detailed information is available for the admin
     */
    owner: (string | UserListResponseDTO);
    /**
     * Detailed information is available for the admin
     */
    userSource?: (string | UserListResponseDTO);
    resourceType?: TransactionResponseDTO.resourceType;
    resource?: (string | TransactionBookingResponseDTO | TransactionProductResponseDTO);
    booking?: TransactionBookingResponseDTO;
    city: CityPopulateResponseDTO;
    charity?: string;
    paid: number;
    amount: number;
    commission: number;
    additionalAmount: number;
    paymentBalance: number;
    charityDonation?: number;
    cancelPayment?: number;
    title: string;
    description: string;
    type: TransactionResponseDTO.type;
    payoutType?: TransactionResponseDTO.payoutType;
    payoutDetails?: (PayoutDetailsPaypalResponseDTO | PayoutDetailsStripeBankResponseDTO | PayoutDetailsStripeCardResponseDTO);
    category: TransactionResponseDTO.category;
    movementFunds: TransactionResponseDTO.movementFunds;
    status: TransactionResponseDTO.status;
    createdAt: string;
    updatedAt: string;
};

export namespace TransactionResponseDTO {

    export enum resourceType {
        PRODUCT = 'Product',
        BOOKING = 'Booking',
    }

    export enum type {
        PAYMENT = 'payment',
        PAYOUT = 'payout',
        MEMBERSHIP = 'membership',
        REFERRAL = 'referral',
        REFUND = 'refund',
    }

    export enum payoutType {
        STRIPE = 'stripe',
        PAYPAL = 'paypal',
    }

    export enum category {
        PRODUCT_PAYMENT = 'productPayment',
        CREATE_BOOKING = 'createBooking',
        BOOKING_PAYMENT_TALENT = 'bookingPaymentTalent',
        BOOKING_UPDATE_PAYMENT_TALENTS = 'bookingUpdatePaymentTalents',
        BOOKING_PAYMENT_PARTICIPATION = 'bookingPaymentParticipation',
        BALANCE_REPLENISHMENT = 'balanceReplenishment',
        BOOKING_APPROVE_CANCEL = 'bookingApproveCancel',
        BOOKING_PAID_TALENT = 'bookingPaidTalent',
        BOOKING_CHARITY_DONATION = 'bookingCharityDonation',
        BOOKING_PAID_PARTICIPATION = 'bookingPaidParticipation',
        REFERRAL_TALENT = 'referralTalent',
        REFERRAL_CLIENT = 'referralClient',
        MEMBERSHIP_PAYMENT = 'membershipPayment',
        CASH_OUT = 'cashOut',
    }

    export enum movementFunds {
        REPLENISHMENT = 'replenishment',
        WRITE_DOWNS = 'write-downs',
    }

    export enum status {
        PENDING = 'pending',
        SUCCEEDED = 'succeeded',
        CANCELED = 'canceled',
        FAILED = 'failed',
    }


}
