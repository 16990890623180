/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateInviteBookingDTO = {
    name: string;
    /**
     * matchmaking = Date, entertainment = Event, travels = Travel
     */
    eventPreferences: CreateInviteBookingDTO.eventPreferences;
    /**
     * Send the _id
     */
    venue: string;
    /**
     * Send the _id
     */
    userId: string;
    /**
     * There is if "eventPreferences = travels". Send the name.
     */
    cityTravel?: string;
    /**
     * There is if "eventPreferences = travels". Send the _id.
     */
    countryTravel?: string;
    /**
     * There is if "eventPreferences != travels"
     */
    reservationName?: string;
    additionalComment?: string;
    additionalCommentClient?: string;
    whatIncludedTalent?: string;
    whatIncludedClient?: string;
    genders: Array<'male' | 'female'>;
    rateTalent: number;
    startDate: string;
    endDate: string;
    uploadPhotos?: Array<Blob>;
};

export namespace CreateInviteBookingDTO {

    /**
     * matchmaking = Date, entertainment = Event, travels = Travel
     */
    export enum eventPreferences {
        ENTERTAINMENT = 'entertainment',
        MATCHMAKING = 'matchmaking',
        TRAVELS = 'travels',
    }


}
