/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';

export type LikeVenueResponseDTO = {
    _id: string;
    name: string;
    /**
     * If type = travel then this value may not be.
     */
    city?: CityPopulateResponseDTO;
    logo: string;
    photos: Array<string>;
    /**
     * If type = travel then this value may not be.
     */
    type?: string;
    experienceType: LikeVenueResponseDTO.experienceType;
};

export namespace LikeVenueResponseDTO {

    export enum experienceType {
        EVENT = 'event',
        TRAVEL = 'travel',
    }


}
