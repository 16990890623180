/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserListResponseDTO } from './UserListResponseDTO';

export type ProductResponseDTO = {
    _id: string;
    name: string;
    description: string;
    price: number;
    value: number;
    paymentCount: number;
    /**
     * There is for the type boostFeed
     */
    interval?: ProductResponseDTO.interval;
    userRole: ProductResponseDTO.userRole;
    /**
     * Available only to the admin
     */
    onlyUsers?: Array<(string | UserListResponseDTO)>;
    type: ProductResponseDTO.type;
    status: ProductResponseDTO.status;
    createdAt: string;
    updatedAt: string;
};

export namespace ProductResponseDTO {

    /**
     * There is for the type boostFeed
     */
    export enum interval {
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
        YEAR = 'year',
    }

    export enum userRole {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum type {
        INVITE = 'invite',
        BOOST_FEED = 'boostFeed',
    }

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}
